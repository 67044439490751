<template>
  <div>
    <h5 class="info-text"> {{ $t('almostthere') }} </h5>
    <div class="row justify-content-center">

      <div class="col-lg-9 mx-auto" v-if="!model.bulkfol && !model.bulkremfol && !model.bulkremadd">

        <div class="row" v-if="alert_me">
          <div class="col-sm-12 text-center">
            <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;">
              {{ alert_msg }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 text-center">
            <p class="lead">{{ $t('strategysummary') }} {{ $t('oftxt') }} <strong>{{ model.name }}</strong></p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 lead">
            <i class="fa  fa-th-large"></i> {{ model.account}} ({{ (model.skipMaster) ? $t('followertxt') : $t('master') }})
          </div>
        </div>

        <div class="row" v-if="!model.master">
          <div class="col-sm-6">
            <h6>{{ $t('allocation') }}</h6> {{ allocationMethodTitle }}
          </div>
          <div class="col-sm-6">
            <h6>{{ allocationMethodTitle }}</h6>
            <span v-if="model.allocationMethod !== 'lotMoneyRatio'">{{ allocationValue }}</span>
            <span v-else>{{ model.lotMoneyRatioX }} <i>forEach</i> {{ model.lotMoneyRatioY }}</span>
          </div>
        </div>

        <div class="row" v-if="!model.master">
          <div class="col-sm-6">
            <h6>{{ $t('copyOpenTradesTxt') }}</h6> {{ (model.copyOpenTrades) ? $t('yestxt') : $t('notxt') }}
          </div>
          <div class="col-sm-6">
            <h6>{{ $t('copytradessincetxt') }}</h6> {{ (model.copyTradesSince) ? model.copyTradesSince : ((model.copyOpenTrades) ? $t('alwaystxt') : $t('notavailable')) }}
          </div>
        </div>

        <div class="row" v-if="!model.master">
          <div class="col-sm-6">
            <h6>{{ $t('assetspairstoreceivesignalstxt') }}</h6> {{ assettorcvsignals[model.assetsPairsToReceiveSignals] }}
          </div>
          <div class="col-sm-6" v-if="model.assetsPairsToReceiveSignals != 'all'">
            <h6>{{ assettorcvsignals[model.assetsPairsToReceiveSignals] }}</h6>
            {{ (model.onlyTheseAssetsPairsReceiveSignal) ? model.onlyTheseAssetsPairsReceiveSignal : ((model.allButTheseAssetsPairsReceiveSignal) ? model.allButTheseAssetsPairsReceiveSignal : $t('alltxt') ) }}
          </div>
        </div>

        <div class="row" v-if="!model.master">
          <div class="col-sm-6">
            <h6>{{ $t('closealltradesatoveralllosstxt') }}</h6> {{ (model.closeAllTradesAtOverallLoss) ? $t('yestxt') : $t('notxt') }}
          </div>
          <div class="col-sm-6" v-if="model.closeAllTradesAtOverallLossChoice == 'lossPercentToCloseProfit'">
            <h6>{{ $t('losspercenttocloseprofittxt') }}</h6> {{ (model.lossPercentToCloseProfit) ? model.lossPercentToCloseProfit : $t('notavailable') }}
          </div>
          <div class="col-sm-6" v-if="model.closeAllTradesAtOverallLossChoice == 'lossMoneyToCloseProfit'">
            <h6>{{ $t('lossmoneytocloseprofittxt') }}</h6> {{ (model.lossMoneyToCloseProfit) ? model.lossMoneyToCloseProfit : $t('notavailable') }}
          </div>
        </div>

        <div class="row" v-if="!model.master">
          <div class="col-sm-6">
            <h6>{{ $t('nonewtradesdrawdownabovetxt') }}</h6> {{ (model.noNewTradesDrawdownAbove) ? model.noNewTradesDrawdownAbove : $t('notavailable') }}
          </div>
          <div class="col-sm-6">
            <h6>{{ $t('receivenewtradesignalstxt') }}</h6> {{ (model.receiveNewTradeSignals) ? $t('yestxt') : $t('notxt') }}
          </div>
        </div>

        <div class="row" v-if="model.master">
          <div class="col-sm-6">
            <h6>{{ $t('pricing_public') }}</h6> {{ (model.public) ? $t('yestxt') : $t('notxt') }}
          </div>
          <div class="col-sm-6">
            <h6>{{ $t('public_pricetxt') }}</h6> {{ (model.public_price && model.public) ? model.public_price : '0.00' }}
          </div>
        </div>

        <div class="row" v-if="model.master">
          <div class="col-sm-12">
            <h6>{{ $t('helper_fixedfee') }}</h6>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 text-center">
            <p class="lead">
              <p-checkbox v-model="model.agree" class="label-check">{{ $t('pleaseconfirm') }}</p-checkbox>
            </p>
          </div>
        </div>

      </div>

      <div class="col-lg-9 mx-auto" v-else>

        <div class="row" v-if="alert_me">
          <div class="col-sm-12 text-center">
            <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;">
              {{ alert_msg }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 text-center" v-if="model.bulkfol">
            <p class="lead">{{ $t('accountstofollow') }} <strong>{{ model.stratName }}</strong></p>
          </div>
          <div class="col-sm-12 text-center" v-if="model.bulkremfol">
            <p class="lead">{{ $t('accountstoremovefollow') }} <strong>{{ model.stratName }}</strong></p>
          </div>
          <div class="col-sm-12 text-center" v-if="model.bulkremadd">
            <p class="lead">{{ $t('accountstoremove') }}</p>
          </div>
        </div>

        <div class="row">

          <div class="col-sm-4" v-for="acc in model.accounts">
            <div class="form-check">
              <label class="form-check-label">
                <div class="row">
                  <div class="acc-term earn-more" v-if="acc.logo">
                    <img :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${acc.logo}.png`">
                  </div>
                  <div>
                    <h5 style="color: black;">{{ acc.account }}</h5>
                    <span class="text-muted" v-if="acc.balance">{{ $t('balance') }} : {{ acc.balance.toFixed(2) }}</span>
                  </div>
                </div>
              </label>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-sm-12 text-center">
            <p class="lead">
              <p-checkbox v-model="model.agree" class="label-check">{{ $t('pleaseconfirm_alt') }}</p-checkbox>
            </p>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>
<script scoped>
  const user_data = JSON.parse(localStorage.getItem('user-info'));

  export default {
    components: {
      //IconCheckbox
    },
    data() {
      return {
        alert_me: false, alert_msg: null, alert_class: 'text-danger',
        model: { 'agree': false, 'junk': 'junk' }, allocmeths: [
          {'prop': 'eqRatio', 'label': 'Equity Ratio' }, {'prop': 'balRatio', 'label': 'Balance Ratio' },
          {'prop': 'lotMultiplier', 'label': 'Lot Multiplier' }, {'prop': 'lotMoneyRatio', 'label': 'Lot Money Ratio' },
          {'prop': 'fixedLots', 'label': 'Fixed Lots' }
        ], assettorcvsignals: {
          'all': 'All', 'onlyTheseAssetsPairsReceiveSignal': 'Only These',
          'allButTheseAssetsPairsReceiveSignal': 'All but These'
        }, allocationValue: null, allocationMethodTitle: null,
        helper_assetspairstoreceivesignalstxt: this.$t('helper_assetspairstoreceivesignalstxt')
      }
    },
    methods: {
      reload(wizModel) {
        this.model = Object.assign({}, wizModel);
        this.model.agree = false;
        const allocIdx = this.allocmeths.findIndex(a => a.prop === wizModel.allocationMethod);
        if (allocIdx >= 0) {
          this.allocationMethodTitle = this.allocmeths[allocIdx]['label'];
          if (this.allocationMethodTitle == 'Equity Ratio') {
            this.allocationMethodTitle = this.$t('helper_eqratio');
          }
          if (this.allocationMethodTitle == 'Balance Ratio') {
            this.allocationMethodTitle = this.$t('helper_balratio');
          }
          if (this.allocationMethodTitle == 'Lot Multiplier') {
            this.allocationMethodTitle = this.$t('helper_lotmultiplier');
          }
          if (this.allocationMethodTitle == 'Lot Money Ratio') {
            this.allocationMethodTitle = this.$t('helper_lotmoneyratiox');
          }
          if (this.allocationMethodTitle == 'Fixed Lots') {
            this.allocationMethodTitle = this.$t('helper_fixedlots');
          }
          this.allocationValue = wizModel[wizModel.allocationMethod];
        }
      },
      validate() {
        return this.$validator.validateAll().then(res => {
          if (!this.model.agree) {
            // this.alert_me = true;
            if (this.model.bulkfol) {
              // this.alert_msg = this.$t('confirmfollow');
              this.$toast.warning(this.$t('confirmfollow'));
            }
            else if (this.model.bulkremfol) {
              // this.alert_msg = this.$t('confirmfollowremove');
              this.$toast.warning(this.$t('confirmfollowremove'));
            }
            else if (this.model.bulkremadd) {
              // this.alert_msg = this.$t('confirmdelaccounts');
              this.$toast.warning(this.$t('confirmdelaccounts'));
            }
            else {
              // this.alert_msg = this.$t('needtoagree');
              this.$toast.warning(this.$t('needtoagree'));
            }
            return false;
          }

          this.$emit('on-validated', res, this.model);
          return res
        });
      }
    },
    mounted() {
      // Admin User or NOT
      if (user_data['roles'].indexOf('admin') >= 0) {
        this.adminusr = true;
      }
    }
  }
</script>
<style>
  .acc-term img {
    width: 45px;
    margin: 0 8px 0 12px;
    border-radius: 50%;
    border: 1px solid silver;
  }

  .acc-check h5 {
    margin-bottom: inherit;
  }
</style>
