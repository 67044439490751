<template>
  <div>
    <!-- In progress -->
    <div v-if="inprogress" class="text-center">
      <h2>{{ inprogresstxt }}</h2>
      <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
    </div>

    <h5 class="info-text" v-if="!inprogress"> {{ $t('choosemasteraccount') }} </h5>
    <div class="row justify-content-center" v-if="!inprogress">

      <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;" v-if="alert_me">
        {{ alert_msg }}
      </div>

      <div class="col-lg-8 mx-auto">
        <div class="row">

          <div class="col-sm-12">

            <el-select class="select-default form-control" v-model="model.account" :placeholder="helper_accchoose" style="margin-bottom: 10px;border: 0;" v-on:change="nameStrat">
              <el-option
                class="select-default"
                v-for="acc in accounts"
                :key="acc.account" :label="acc.label" :value="acc.account">
              </el-option>
            </el-select>

          </div>

        </div>

        <div class="row" v-if="model.account">
          <div class="col-sm-12">
            <h5 class="info-text"> {{ $t('namestrategy') }} </h5>
          </div>
        </div>

        <div class="row" v-if="model.account">
          <div class="col-sm-12">
            <span class="category sub-text text-danger" v-if="alert_stratname">
              {{ $t('charnotallowed') }} {{ $t('onlyalphanumerics') }}
            </span>
            <fg-input v-model="model.name"
                      addon-left-icon="nc-icon nc-sound-wave"
                      type="text"
                      :maxlength="stratNameMaxLength"
                      :placeholder="helper_stratname"
                      v-on:keyup="checkName"
            ></fg-input>
            <span class="category sub-text" style="display: flex;">
              {{ $t('onlyalphanumerics') }}
            </span>
          </div>
        </div>

        <div class="row" v-if="model.account" style="padding-bottom: 15px;">
          <div class="col-sm-2 mx-auto">&nbsp;</div>
          <div class="col-sm-4 mx-auto" style="top: 28px;">
            <span style="display: flex;">
              <div class="info-icon info-l10 info-t5">
                <el-tooltip :content="helper_sharetxt" :open-delay="100" placement="top" popper-class="adjust-width">
                  <i class="nc-icon nc-alert-circle-i"></i>
                </el-tooltip>
              </div>
              <el-tooltip :content="strategyTooltip" v-if="!canBePublic" :open-delay="100" placement="top" popper-class="adjust-width">
                  <p-checkbox  disabled>{{ $t('pricing_public') }} ?</p-checkbox>
              </el-tooltip>
              <p-checkbox v-else v-model="model.public" :disabled="(preloaded && model.public)">{{ $t('pricing_public') }}?</p-checkbox>
            </span>
          </div>
          <div class="col-sm-4 mx-auto" v-if="model.public">
            <span class="category sub-text" v-if="model.public_price" style="display: flex;">
              {{ public_pricetxt }}: {{ model.public_price }}%
            </span>

            <span style="display: flex;">
              <el-tooltip :content="helper_public_pricetxt" :open-delay="100" placement="top" popper-class="adjust-width">
                <i class="nc-icon nc-alert-circle-i info-icon info-t20 info-l0"></i>
              </el-tooltip>
              <el-select class="select-default form-control" v-model="model.public_price" :placeholder="public_pricetxt" style="margin-bottom: 10px;border: 0;"  v-bind:disabled="!model.public">
                <el-option
                  class="select-default"
                  v-for="p in percentage"
                  :key="p.prop" :label="p.label" :value="p.prop">
                </el-option>
              </el-select>
            </span>

          </div>
          <div class="col-sm-2 mx-auto">&nbsp;</div>
        </div>
        <br>
        <div class="row" v-if="model.account && model.public" style="padding-bottom: 15px;">
          <div class="col-sm-8 mx-auto">
            <label> {{ $t('description') }} </label>
            <textarea v-model="model.public_desc" id="description" class="form-control" :placeholder="helper_public_desctxt" rows="3" style="padding: 10px;" v-bind:disabled="!model.public"></textarea>
          </div>
        </div>
        <div class="row" v-if="model.account && model.public" style="padding-bottom: 15px;">
          <div class="col-sm-8 mx-auto">
            <label>
                <el-tooltip :content="$t('helper_minimum_balance')" :open-delay="100" placement="top" popper-class="adjust-width">
                  <i class="nc-icon nc-alert-circle-i info-icon"></i>
                </el-tooltip>
                {{ $t('minimum_balance_text') }}
            </label>
            <fg-input v-model="model.minimum_balance" type="number" min="0"></fg-input>
          </div>
        </div>


        <div class="row" v-if="adminusr">
          <div class="col-sm-8 mx-auto">
            <h6>{{ $t('helper_tradedassets') }} (SUPER ADMIN ONLY)</h6>
            <fg-input v-model="sa.tradedAssets" addon-left-icon="fa fa-keyboard-o" type="text" :placeholder="helper_tradedassets"></fg-input>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>
<script scoped>
  import Vue from 'vue'
  import {Tooltip, Select, Option} from 'element-ui'
  import {Checkbox} from 'src/components/UIComponents'

  const MAXSTRATCHAR = 24;
  const user_data = JSON.parse(localStorage.getItem('user-info'));

  Vue.use(Select)
  Vue.use(Option)

  export default {
    components: {
      [Tooltip.name]: Tooltip,
      [Checkbox.name]: Checkbox
    },
    computed: {
      stratNameMaxLength() {
        return MAXSTRATCHAR || 24
      },
    },
    data() {
      return {
        accounts: [],
        inprogress: true,
        ueml: null,
        helper_accchoose: this.$t('chooseaccount'),
        helper_sharetxt: this.$t('helper_sharetxt'),
        helper_public_pricetxt: this.$t('helper_public_pricetxt'),
        helper_public_desctxt: this.$t('helper_public_desctxt'),
        public_pricetxt: this.$t('public_pricetxt'),
        helper_tradedassets: this.$t('helper_tradedassets'),
        adminusr: false,
        validname: false,
        client_or_trader: 'client_pays',
        model: {
          account: null,
          account_id: null,
          name: null,
          uid: null,
          url: null,
          public: false,
          public_price: 10,
          public_desc: null,
          notagreed: true,
          master: true,
          update: false,
          sachange: false,
          sa_tradedAssets: null,
          minimum_balance: 0
        },
        helper_stratname: this.$t('helper_stratname'),
        preloaded: false,
        alert_me: false,
        alert_msg: null,
        alert_class: 'text-danger',
        percentage: [
          { 'prop': 5, 'label': '5%' },
          { 'prop': 10, 'label': '10%' },
          { 'prop': 15, 'label': '15%' },
          { 'prop': 20, 'label': '20%' },
          { 'prop': 25, 'label': '25%' },
          { 'prop': 30, 'label': '30%' },
          { 'prop': 35, 'label': '35%' },
          { 'prop': 40, 'label': '40%' },
          { 'prop': 45, 'label': '45%' },
          { 'prop': 50, 'label': '50%' }
        ],
        alert_stratname: false,
        sa: {
          old_tradedAssets: null,
          tradedAssets: null
        },
        helper_fixedfee: this.$t('helper_fixedfee'),
        inprogresstxt: this.$t('loadingaccounts'),
        canBePublic: false,
        strategyTooltip: '',
      }
    },
    methods: {
      checkName(e) {
        var regex = /^[A-Za-z0-9\s]+$/;
        //Validate TextBox value against the Regex.
        var isValid = regex.test(this.model.name);
        this.alert_stratname = false;

        const revert = err => {
          this.model.name = this.model.name.slice(0, -1);
        };

        if (!isValid) {
          this.alert_stratname = true;
          // Revert to the previous
          setTimeout(revert, 1000);
        } else {
          if (this.model.name !== undefined && this.model.name !== null && this.model.name.length <= MAXSTRATCHAR) {
            const proceedCreate = resp => {
              if (!resp.data['available']) {
                this.$toast.warning(this.$t('choosedifferentstratname'));
                this.validname = false;
              } else {
                this.validname = true;
              }
            };
            this.$checkStratName(this.model.name).then(proceedCreate, this.failop);
          }
          else {
            this.alert_stratname = true;
            // Revert to the previous
            setTimeout(revert, 1000);
          }
        }
      },
      hitLimits(obj, min, max) {
        this.alert_me = false;
        let showalert = false;
        if (max !== null && parseFloat(obj) > max) {
          obj = max;
          showalert = true;
        }
        if (min !== null && parseFloat(obj) < min) {
          obj = min;
          showalert = true;
        }

        if (showalert) {
          this.alert_msg = this.$t('youreachedlimit') +" "+ min +" "+ this.$t('and') + " "+ max;
          this.$toast.warning(this.alert_msg)
        }
        return obj;
      },
      validate() {
        return this.$validator.validateAll().then(res => {
          this.alert_me = false;
          if (this.model.account === null || this.model.name === null || this.model.account === '' || this.model.name === '' || (this.model.public && (this.model.public_desc === null || this.model.public_desc === ''))) {
            this.$toast.warning(this.$t('populatefieldbelow'));
            res = false;
          }
          else {
            this.model.uid = this.ueml;
            if (this.adminusr) {
              if (this.sa.old_tradedAssets != this.sa.tradedAssets) {
                this.model.sachange = true;
                this.model.sa_tradedAssets = this.sa.tradedAssets;
              }
            }
            res = this.validname;
            console.log(this.model)
            this.$emit('on-validated', res, this.model);
          }

          return res;
        });
      },
      loadAccounts(resp) {
        this.accounts = [];
        for(var i=0;i < resp.data.validated.length;i++) {
          let verified = resp.data.validated[i];
          if (verified['reporting_status'] == 'live' && verified['strategies'].length == 0) {
            this.accounts.push({
              'account': verified.account,
              'server': verified.server,
              'label': verified.account +" ("+ verified.server +")",
              'url': verified.url,
              'id': verified.id
            })
          }
        }
        // If AID exists
        if (this.$route.params.aid) {
          const aidx = this.accounts.findIndex(a => parseInt(a.id) === parseInt(this.$route.params.aid));
          if (aidx >= 0) {
            this.model.account = this.accounts[aidx].account;
            // Set the Account Drop-down
            this.nameStrat();
          }
        }

        this.inprogress = false;
      },
      loadStrategies(resp) {
        this.accounts = [];
        for(var i=0;i < resp.data.length;i++) {
          const a = resp.data[i];
          if (a.acc_type === 'master') {
            this.accounts.push({
              'account': a.account, 'server': '', 'label': a.account, 'url': a.strat_url, 'id': a.acc_id
            });
            this.model.name = a.strat_name;
            this.model.update = a.strat_id;
            this.model.public = a.discoverable;
            if (this.model.public) {
              this.preloaded = true;
              this.model.public_price = parseFloat(a.price) * 100;
              this.model.public_desc = a.strat_desc;
              this.client_or_trader = "client_pays";
            }
            if (this.adminusr) {
              this.sa.old_tradedAssets = a.tradedAssets;
              this.sa.tradedAssets = a.tradedAssets;
            }
            break;
          }
        }
        this.model.account = this.accounts[0].account;
        // Set the Account Drop-down
        this.nameStrat();
        this.model.uid = this.ueml;
        // Finish in Progress
        this.inprogress = false;
      },
      getStrategy(sid) {
        if (this.adminusr) {
          this.ueml = this.$route.params.userid;
        }
        else {
          this.ueml = user_data.email;
        }
        this.$getStrategies(this.ueml, sid).then(this.loadStrategies, this.failop);
      },
      getAccounts() {
        this.$getAllAccounts_v2({
          eml: this.adminusr ? this.$route.params.userid : user_data.email,
        }).then(this.loadAccounts, this.failop);
      },
      nameStrat() {
        let aidx = this.accounts.findIndex(a => a.account == this.model.account);
        if (aidx >= 0) {
          if (!this.model.update) {
            this.model.name = this.accounts[aidx].url;
          }
          this.model.url = this.accounts[aidx].url;
          this.model.account_id = this.accounts[aidx].id;
          this.validname = true;
        }

        const sucess = resp => {
          if (!resp.success) {
            fail(resp)
          } else {
            this.canBePublic = true
          }
        }
        const fail = resp => {
          this.model.public = false
          this.canBePublic = false
          this.strategyTooltip = this.$t(resp.msg)
        }
        this.$canCreatePublicStrategy(this.model.account).then(sucess, fail)
      },
      failop(error) {
        this.$toast.warning(error);
      }
    },
    mounted() {
      // Admin User or NOT
      if (user_data['roles'].indexOf('admin') >= 0) {
        this.adminusr = true;
      }

      if (this.$route.params.sid) {
        // Load Master Account Strategy
        this.getStrategy(this.$route.params.sid);
      } else {
        this.getAccounts();
      }
    },
    created(){
      this.$setTranslatingInProgress()
    },
    beforeDestroy(){
      this.$root.$off("showTranslatingProgress")
    }
  }
</script>
<style lang="scss">
  .form-check.disabled {
    display: flex;
  }
</style>
